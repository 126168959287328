import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { PolicyList } from '@components/Policies/PolicyList';
import * as contentful from 'types/contentful';
import { MainMenu } from '@components/MainMenu';
import SortBar from '@components/SortBar';
import { PolicyCardsSearch } from '@components/Policies/PolicyCardsSearch';
import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import { SuppliersCaveat } from '@components/SuppliersCaveat';
import { InlineContainer } from '@components/InlineContainer';
import { Item } from '@components/SortBar/types';
import { IPageProps } from '../types';

const sortByItem: Item[] = [
  {
    label: 'Alphabetically',
    fieldName: 'hotelChainName',
  },
  {
    label: 'Last updated',
    fieldName: 'updatedAt',
  },
];

const PolicyHotelChainsPage = (props: IPageProps<contentful.HotelChain[]>): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname },
  } = props;
  const [filterData, setFilterData] = useState<contentful.HotelChain[]>([]);
  const [emptyItemsMessageVisible, setEmptyItemsMessageVisibile] = useState(false);
  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortByItem[0]);

  const displayFilterData = (data: contentful.HotelChain[]): void => {
    setEmptyItemsMessageVisibile(false);
    setFilterData(data);
  };

  const handleSortSelect = (data: contentful.HotelChain[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    setFilterData(data);
  };

  const handleSearchChange = (data: contentful.HotelChain[], isResetData: boolean): void => {
    if (isResetData) {
      displayFilterData(pageContext.data);

      return;
    }

    if (isEmpty(data)) {
      setEmptyItemsMessageVisibile(true);
    } else {
      displayFilterData(data);
    }
  };

  const data = !isEmpty(filterData) ? filterData : pageContext.data;

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname} />
      <InlineContainer>
        <PolicyCardsSearch
          handleValueChange={handleSearchChange}
          data={pageContext.data}
          searchFields={['hotelChainName']}
          filterByFieldName="hotelChainName"
        />
        <SortBar
          data={data}
          options={sortByItem}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
        <SuppliersCaveat />
      </InlineContainer>
      {emptyItemsMessageVisible ? (
        <NoCardMatchesFoundMessage />
      ) : (
        <PolicyList
          getItemProps={item => ({
            items: [
              {
                content: item.faceMasks?.json,
                title: 'Face Masks',
                classImg: 'faceMasksImg',
              },
              {
                content: item.healthChecks?.json,
                title: 'Health Checks',
                classImg: 'healthChecksImg',
              },
              {
                content: item.socialDistancing?.json,
                title: 'Social Distancing',
                classImg: 'socialDistancingImg',
              },
              {
                content: item.cleaningPolicy?.json,
                title: 'Cleaning Policy',
                classImg: 'cleaningPolicyImg',
              },
              {
                content: item.amenities?.json,
                title: 'Amenities',
                classImg: 'amenitiesImg',
              },
              {
                content: item.fb?.json,
                title: 'F&B',
                classImg: 'foodImg',
              },
            ],
            title: item.hotelChainName,
            updatedAt: item.updatedAt,
            logoUrl: item.logoImage?.file.url,
            link: item.hotelChainUrl
              ? { url: item.hotelChainUrl.link, label: item.hotelChainUrl.label }
              : undefined,
            key: item.id,
            id: `id${item.id}`,
          })}
          data={data}
        />
      )}
    </>
  );
};

export default PolicyHotelChainsPage;
